export default {
  youtube: 'Time Gallery of T-REC',
  youtubeLink: 'https://www.youtube.com/watch?v=KBEM3LDCNAk',
  title: 'Green New Era Series Websites',
  webTitle: 'Green New Era',
  webYear: '2023',
  webImages: '/images/bg1.png',
  webText:
    'This year\'s theme of "Green New Era 2023 !" invites the public to join hands to promote the development of renewable energy and certificates in Taiwan. The display content of the five theme areas is to provide popular knowledge to help the public\'s understanding of the renewable energy certificate system.',
  webBtn: 'Enter',
  // webUrl: 'https://virtual-exhibition.kingone-design.com:8048/2022',
  webUrl: '2023',
  slideTitle: 'Previous Websites',
}
