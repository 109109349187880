export default {
  youtube: '再生能源憑證時光廊',
  youtubeLink: 'https://www.youtube.com/watch?v=KBEM3LDCNAk',
  title: '經濟部標準檢驗局 ‧ 綠能新世代',
  webTitle: '擁抱！綠能新世代',
  webYear: '2023',
  webImages: '/images/bg1.png',
  webText:
    '從來吧！到驅動！標準檢驗局這次以「擁抱！綠能新世代！」為主題，邀約民眾瀏覽精彩的五大主題展區，了解再生能源及憑證發展趨勢，擁抱綠能新知，共同邁向淨零未來！',
  webBtn: '前往網站',
  webUrl: '2023',
  slideTitle: '歷年能源週網站',
}
